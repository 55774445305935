import api from '@/services/api/api-cbr';

export default class CbrService {
  async list(query) {
    try {
      let res = await api.list(query);
      if (!res.results) throw new Error('Something wrong and slots not loading. Please, reload page or try later.');
      let data = {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        }
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTotals(query) {
    try  {
      return await api.getTotals(query);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}