import Dayjs from 'dayjs';
import cbrUpdateItem from './models/cbrUpdateItem.model';
import {
  getDOWDutchDDD,
  convertToDefaultDateFormat,
  convertToDefaultDateHourFormat,
} from '@/util';

export const SLOT_ARG = {
  LOCATION: 'location',
  DATETIME: 'datetime',
  DATE_CREATED: 'date_created',
  DIFFERENCE: 'difference',
  TOTAL_AMOUNT: 'total_amount',
};

export default class CbrUpdatesGroup {
  constructor() {
    this.list = {};
    this.$set = null;
  }

  groupedList(payload, set) {
    this.$set = set;

    payload.forEach(slot => {
      let weekDay = getDOWDutchDDD(Dayjs(slot[SLOT_ARG.DATE_CREATED]).day());
      let _convertTime = convertToDefaultDateHourFormat(slot[SLOT_ARG.DATE_CREATED]);
      let date = `${weekDay} ${_convertTime}`;
      let groupDate = convertToDefaultDateFormat(slot[SLOT_ARG.DATETIME]);
      let groupWeekDay = getDOWDutchDDD(Dayjs(slot[SLOT_ARG.DATETIME]).day());
      slot.date = date;
      let groupName = `${slot[SLOT_ARG.LOCATION]} - ${groupWeekDay} ${groupDate}`;
      //
      if (date in this.list) this.setToLocation(slot, date, groupName);
      else this.setToDate(slot, date, groupName);
    });
  }

  setToLocation(slot, date, groupName) {
    if (groupName in this.list[date])
      this.list[date][groupName].push(new cbrUpdateItem(slot));
    else this.$set(this.list[date], [groupName], [new cbrUpdateItem(slot)]);
  }

  setToDate(slot, date, groupName) {
    let item = {
      [groupName]: [new cbrUpdateItem(slot)]
    };
    this.$set(this.list, date, item);
  }

  reset() {
    this.list = {};
  }
}