import auth from '@/services/CRMinitAuth';

const list = query =>
  auth.get(`${process.env.VUE_APP_CRM_API}reload_slots_log/`, {
    params: query
  });

const getTotals = query =>
  auth.get(`${process.env.VUE_APP_CRM_API}reload_slots_log/total_difference/`, {
    params: query
  });

export default {
  list,
  getTotals
};