import { SLOT_ARG } from '../cbr-group';

export default class CbrUpdateItem {
  constructor(item = {}) {
    this.location = item[SLOT_ARG.LOCATION];
    this.datetime = item[SLOT_ARG.DATETIME];
    this.date_created = item[SLOT_ARG.DATE_CREATED];
    this.total_amount = item[SLOT_ARG.TOTAL_AMOUNT];
    this.difference = item[SLOT_ARG.DIFFERENCE];
  }
}