export const SLOTS_UPDATES_LIST_TABS = {
  TOP: 'TOP',
  MIJN: 'MIJN'
};

export const JUMBOTRON_FILTERS = {
  EXAM_LOCATION: 'location',
  RELEASE_DATE_AFTER: 'release_date_after',
  RELEASE_DATE_BEFORE: 'release_date_before',
  SLOTS_AFTER: 'slot_date_after',
  SLOTS_BEFORE: 'slot_date_before',
  SLOTS_TIME_AFTER: 'slot_time_after',
  SLOTS_TIME_BEFORE: 'slot_time_before',
  TOTAL_DIFFERENCE: 'total_difference'
};
