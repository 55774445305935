<template lang="pug">
  .page-container.cbr_updates
    portal(to="toolbar-title")
      toolbar-title CBR Updates

    .page-container__block
      filters(:loading="loading")

    .page-container__block.border-b
      .jumbotron-title.px-5.py-2 Total Slots: {{ totalSlots }}

    .page-container__block.border-b
      .d-flex
        tabs(@change="updateList")

    .page-container__list
      list(
        :list="getGroupedList"
        :loading="loading"
        :pagination="pagination"
        @loadMore="loadMore"
      )
</template>

<script>
import CbrService from './core/cbr-service'
import PaginationModel from '@/models/paginationModel'
import paginationMixin from '@/mixins/pagination.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import CbrUpdatesGroup from './core/cbr-group'
import { JUMBOTRON_FILTERS } from './core/cbr-conts'
import { mapGetters } from "vuex";

export default {
  name: 'CbrUpdatesPage',

  mixins: [ paginationMixin, errorsMixin, featureFlagsMixin ],

  computed: {
    getGroupedList() {
      if (this.groupedList && this.groupedList.list) return this.groupedList.list
      else return {}
    }
  },

  data: () => ({
    cbrService: new CbrService(),
    pagination: {},
    primaryPagination: {},
    groupedList: new CbrUpdatesGroup(),
    loading: false,
    totalSlots: 0
  }),

  created() {
    this.setPageSize(50)
    this.setQuery({ page: 1 }, false)
    this.preSelectPageData()
  },

  async mounted() {
    await this.loadData({})
  },

  methods: {
    preSelectPageData() {
      if (!this.$route.query[JUMBOTRON_FILTERS.TOTAL_DIFFERENCE]) {
        this.setQuery({ [JUMBOTRON_FILTERS.TOTAL_DIFFERENCE]: 3 }, false)
      }
      if (!this.$route.query[JUMBOTRON_FILTERS.EXAM_LOCATION]) {
        this.setQuery({ [JUMBOTRON_FILTERS.EXAM_LOCATION]: 'Alkmaar (Olympiaweg 28)' }, false)
      }
      if (!this.$route.query[JUMBOTRON_FILTERS.SLOTS_TIME_AFTER]) {
        this.setQuery({ [JUMBOTRON_FILTERS.SLOTS_TIME_AFTER]: '13:20' }, false)
      }
    },

    async loadData() {
      await Promise.all([this.loadList({}), this.loadTotals()]);
    },

    async loadList({ more = false }, query) {
      try {
        this.loading = true
        if (!more) await this.resetData()
        let { results, pagination } = await this.cbrService.list({ ...this.$route.query, ...query })
        this.groupedList.groupedList(results, this.$set)
        this.pagination = new PaginationModel(this.setPaginationParams(pagination))
        if (!more) this.setPrimaryPagination()
      } catch (error) {
        console.log(error)
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM })
      } finally {
        this.loading = false
      }
    },

    async loadTotals(query) {
      try {
        let res = await this.cbrService.getTotals({ ...this.$route.query, ...query })
        this.totalSlots = res?.total_difference || 0
      } catch (error) {
        console.log(error)
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM })
      }
    },

    async updateList() {
      await this.resetData()
      await this.loadData()
    },

    async resetData() {
      this.groupedList.reset()
      this.totalSlots = 0
    },

    async loadMore() {
      if (!this.pagination) return
      await this.onLoadMoreClick()
      await this.loadList({ more: true })
    },

    setPrimaryPagination() {
      this.primaryPagination = { page: this.pagination.page, size: this.pagination.size }
    },

    async updateSorting(ordering) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ordering: ordering}})
      await this.loadList({})
    },

    async setQuery(data, load) {
      await this.$router.replace({ query: { ...this.$route.query, ...this.getDefaultPagination(), ...data, timestamp: new Date().getTime() } })
      if (load) await this.loadData({})
    }
  },

  provide: function () {
    return {
      cbrService: this.cbrService,
      getDefaultPagination: this.getDefaultPagination,
      getDefaultPageSize: this.getDefaultPageSize,
      setQuery: this.setQuery
    }
  },

  components: {
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    filters: () => import('./components/CbrUpdatesFilters.vue'),
    tabs: () => import('./components/CbrUpdatesTabs.vue'),
    list: () => import('./components/CbrUpdatesList.vue'),
  }
}
</script>
